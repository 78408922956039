import React from 'react';
//import link
import { Link } from 'react-router-dom';
//import motion
import { motion } from 'framer-motion';
// import transition
import {transition1} from '../transitions';
import Footer from './Footer';

const Portfolio = ({data}) => {
    return (
        <motion.section 
            initial={{ opacity:0 }} 
            animate={{ opacity:1 }} 
            exit={{ opacity:0 }}
            transition={transition1}
            className='section overflow-scroll relative'>
            <div className='items-center px-16 h-full pt-[100px] lg:pt-[140px]'>
                {/* image grid */}
                <div className='grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 auto-rows-fr lg:gap-6 gap-4'>
                    {data.map((item, index) => {
                        return(
                            <motion.div 
                                initial={{ scale:0.8 }} 
                                animate={{ scale:1 }} 
                                exit={{ opacity:0 }}
                                transition={transition1}
                                className="group justify-center h-full w-auto items-center relative flex-auto"
                                >
                                <div className="aspect-w-4 aspect-h-5 w-auto h-full overflow-hidden">
                                    <img className="object-cover h-full w-full object-center" src={item.imgSrc} alt='' />
                                </div>
                                <Link className='cover' to={item.link}>
                                    <p>{item.coverName}</p>
                                </Link>
                            </motion.div>    
                        )
                    })}

                </div>
            </div>
            
        </motion.section>
    );

};
{/* image grid */}
{/* className="group justify-center h-full w-full items-center relative" */}

export default Portfolio;