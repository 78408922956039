import React, { useContext } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import AnimRoutes from './components/AnimRoutes';

import { BrowserRouter as Router } from 'react-router-dom';


const App = () => {
  return <>
    <Router>
      <div className="flex flex-col h-screen">
        <Header />
        <div className="flex-1"> {/* This will allow the content to grow */}
          <AnimRoutes />
        </div>
      </div>
    </Router>
    
  </>
};

export default App;
